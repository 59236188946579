import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";

import {Form, Modal} from 'react-bootstrap';
import {Botao, Card, Input} from '../../componentes';
import {ExibirMensagem, xfetch} from '../../util';
import {BOTAO, HttpVerbo, ICONE, MSG} from '../../util/Constantes';
import {Editor} from "@tinymce/tinymce-react";
import CID from "../../componentes/form/CID";

export default function EditarAtestado(props) {
    const TIPO_ATESTADO = 37;
    const PRAZO = 2;
    const STATUS_ATESTADO_ID = 24;

    const [show, setShow] = useState(false);
    const [anexar, setAnexar] = useState(false);
    const handleClose = () => {
        setShow(false);
        props.funcao();
    }
    const handleShow = () => setShow(true);
    const editorRef = useRef(null);
    const editorJustificativa = useRef(null);
    const [dataInicial, setDataInicial] = useState("");
    const [diasAfastamento, setDiasAfastamento] = useState(1);
    const [listaDeCidsCadastrados, setListaDeCidsCadastrados] = useState([]);
    const [dataFinal, setDataFinal] = useState("");
    const [motivo, setMotivo] = useState({
        idMotivo: null,
        listaMotivo: []
    });
    const [especialidades, setEspecialidades] = useState({
        listaEspecialidades: []
    });
    const [justificar, setJustificar] = useState(false);
    const [descricaoTemporaria, setDescricaoTemporaria] = useState({
        descricao: "",
        justificativa: ""
    });
    const dtInicio = useRef("");
    const dtTermino = useRef("");

    const [dadosAtestado, setDadosAtestado] = useState({
        atestado: {
            dtEmissaoAtestado: null,
            diasAfastamento: null,
            dtValidadeAtestado: null,
            motivo: null,
            informacoesAdicionais: "",
            justificativa: "",
            nmArquivo: "",
            nmMedico: "",
            nrConselho: "",
            cid: "",
            especialidade: null,
            atestadoPdf: null,
            statusAtestadoId: STATUS_ATESTADO_ID,
            usuarioId: localStorage.getItem("id"),
            pacienteId: localStorage.getItem("id")
        }
    });

    const [atestado, setAtestado] = useState(
        {
            dtEmissaoAtestado: null,
            diasAfastamento: null,
            dtValidadeAtestado: null,
            motivoId: null,
            informacoesAdicionais: "",
            justificativa: "",
            nmArquivo: "",
            nmMedico: "",
            nrConselho: "",
            cid: "",
            especialidade: null,
            atestadoPdf: null,
            statusAtestadoId: STATUS_ATESTADO_ID,
            usuarioId: localStorage.getItem("id"),
            pacienteId: localStorage.getItem("id")
        }
    );

    useEffect(() => {
        if (show) {
            xfetch(`/hpm/atestado/atestados/${props.valor}`, {}, HttpVerbo.GET)
                .then(res => res.json())
                .then(json => {
                    setDadosAtestado({...dadosAtestado, atestado: json});
                    atualizarAtestado(json);
                })
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            xfetch(`/hpm/atestado/atestados/${props.valor}`, {}, HttpVerbo.GET)
                .then(res => res.json())
                .then(json => {
                    setDadosAtestado({...dadosAtestado, atestado: json});
                    atualizarAtestado(json);
                })
        }
    }, [anexar]);

    const atualizarAtestado = (dados) => {
        const dataEmissao = dados.dtEmissaoAtestado.split("T");
        const dataValidade = dados.dtValidadeAtestado.split("T");
        dtInicio.current = dataEmissao[0];
        dtTermino.current = dataValidade[0];
        setAtestado({
            id: props.valor,
            dtEmissaoAtestado: dtInicio.current,
            diasAfastamento: dados.diasAfastamento,
            dtValidadeAtestado: dtTermino.current,
            motivoId: dados.motivo.id,
            informacoesAdicionais: dados.informacoesAdicionais,
            justificativa: dados.justificativa,
            nmArquivo: dados.nmArquivo,
            nmMedico: dados.nmMedico,
            nrConselho: dados.nrConselho,
            cid: dados.cid,
            especialidade: dados.especialidade.id,
            atestadoPdf: dados.atestadoPdf,
            statusAtestadoId: STATUS_ATESTADO_ID,
            usuarioId: localStorage.getItem("id"),
            pacienteId: localStorage.getItem("id")
        });
        setDiasAfastamento(dados.diasAfastamento);
        const listaCidsCadastrados = dados.cid.split(",");
        setListaDeCidsCadastrados(listaCidsCadastrados);
    }

    const handleDtInicio = (e) => {
        let dtSelecionada = e.target.value;
        setJustificar(false);
        if (dtSelecionada) {
            dtSelecionada = dtSelecionada + "T00:00";
        }
        setDataInicial(e.target.value);
        dtInicio.current = e.target.value;
        setAtestado({...atestado, dtEmissaoAtestado: dtSelecionada});
        if (calcularPrazoHomologacao(e.target.value) > PRAZO) {
            setJustificar(true);
        }
    }

    function calcularPrazoHomologacao(dtInicial) {
        const dataAtual = new Date();
        const dataInicial = new Date(dtInicial);
        const diferencaEmMilissegundos = dataAtual.getTime() - dataInicial.getTime();
        return diferencaEmMilissegundos / (1000 * 60 * 60 * 24);
    }

    const handleDtFinal = (e) => {
        let dtSelecionada = e;
        if (dtSelecionada) {
            dtSelecionada = dtSelecionada + "T00:00";
        }
        dtTermino.current = e;
        setAtestado({...atestado, dtValidadeAtestado: dtSelecionada});
    }

    const handleMotivo = (e) => {
        let motivo = e.target.value;
        if (motivo) {
            motivo = parseInt(motivo);
        }
        setAtestado({...atestado, motivoId: motivo});
    }

    const handleEspecialidade = (e) => {
        let especialidade = e.value;
        if (especialidade) {
            especialidade = parseInt(especialidade);
        }
        setAtestado({...atestado, especialidade: especialidade});
    }

    const handleDiasAfastamento = (e) => {
        e.preventDefault();
        const dias = parseInt(e.target.value);
        setDiasAfastamento(dias);
        setAtestado(prevAtestado => {
            return {...prevAtestado, diasAfastamento: dias}
        });
    }

    const handleCid = (e) => {
        setAtestado(prevAtestado => {
            return {...prevAtestado, cid: e}
        });
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileName = file.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                const blob = reader.result.split(";base64,").pop();
                setAtestado({...atestado, atestadoPdf: blob, nmArquivo: fileName});
                setAnexar(!anexar);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleNomeProfissionalSaude = (e) => {
        const nomePfs = e.target.value;
        setAtestado({...atestado, nmMedico: nomePfs.toUpperCase()});
    }

    const enviar = () => {
        try {
            atestado.cid = atestado.cid.map(cid => cid.trim());
            atestado.cid = atestado.cid.join(", ");
        } catch (e) {
            console.error("Erro ao converter CID:", e);
        }

        if (editorRef.current) {
            atestado.informacoesAdicionais = editorRef.current.getContent({
                format: "text",
            });
        }
        if (editorJustificativa.current) {
            atestado.justificativa = editorJustificativa.current.getContent({
                format: "text",
            });
        }
        if (justificar && !atestado.justificativa) {
            ExibirMensagem('Prazo de homologação expirado. Não pode enviar sem cadastrar uma justificativa.', MSG.ALERTA);
        }

        atestado.dtEmissaoAtestado = dtInicio.current + "T00:00";
        atestado.dtValidadeAtestado = dtTermino.current + "T00:00";

        console.log("Atestado em Enviar:", atestado);

        xfetch("/hpm/atestado/alterar", atestado, HttpVerbo.PUT)
            .then(json => {
                ExibirMensagem('Atestado enviado com sucesso!', MSG.SUCESSO, '', '', '', '', handleClose());
            })
            .catch((error) => {
                console.error('Erro no cadastro.', error);
            });
    }

    const separarDtInicial = () => {
        const dataPartes = dtInicio.current.split("-");
        const ano = parseInt(dataPartes[0]);
        const mes = parseInt(dataPartes[1]) - 1;
        const dia = parseInt(dataPartes[2]);

        return {ano, mes, dia};
    }

    function calcularDataFinal() {
        const dataSeparada = separarDtInicial();
        const dataSelecionada = new Date(dataSeparada.ano, dataSeparada.mes, dataSeparada.dia);
        let quantidadeDias = parseInt(diasAfastamento);

        if (!isNaN(dataSelecionada.getTime()) && !isNaN(quantidadeDias)) {
            let dataFinalCalculada = new Date(dataSelecionada);
            dataFinalCalculada.setDate(dataFinalCalculada.getDate() + quantidadeDias);
            const dataFormatada = dataFinalCalculada.toISOString().slice(0,10);
            setDataFinal(dataFormatada);
            handleDtFinal(dataFormatada);
        }
    }

    const fetchInterval = 1000 * 60;
    let lastFetchTime = 0;

    const fetchData = () => {
        const currentTime = Date.now();
        const deltaTime = currentTime - lastFetchTime;
        if (deltaTime >= fetchInterval) {
            if (editorRef.current) {
                descricaoTemporaria.descricao = editorRef.current.getContent({
                    format: "text",
                });
            }
            if (editorJustificativa.current){
                descricaoTemporaria.justificativa = editorJustificativa.current.getContent({
                    format: "text",
                });
            }
            xfetch(`/hpm/atestado/alterar`, descricaoTemporaria, HttpVerbo.POST)
                .then((response) => {
                    if (typeof response !== "undefined" ? response.status === "OK" : false) {
                        console.log('Até aqui tudo bem...')
                    }
                })
                .catch(() => console.error('Erro no cadastro.'))
            lastFetchTime = currentTime;
        }
        setTimeout(fetchData, fetchInterval);
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        document.getElementById("file-upload").click(); // Dispara o clique no input oculto
    };

    const handleTipoAtestado = () => {
        xfetch(`/hpm/tipo/opcoes/${TIPO_ATESTADO}`, {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(motivo => setMotivo({ ...motivo, listaMotivo: motivo.resultado }))
    }

    const handleEspecialidades = () => {
        xfetch(`/hpm/especialidade/opcoes`, {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(especialidade => setEspecialidades({ ...especialidades, listaEspecialidades: especialidade.resultado }))
    }

    useEffect(() => {
        if (!show) {
            handleTipoAtestado();
            handleEspecialidades();
        }
    }, []);

    useEffect(() => {
        calcularDataFinal();
    }, [diasAfastamento, dataInicial]);

    return (
        <>
            <Botao cor={props.corDoBotao} icone={props.icone} onClick={handleShow}>{props.nome}</Botao>
            <Modal show={show} onHide={handleClose} size="xl" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-lg-12">
                                <Card titulo="Homologar">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <label htmlFor="idMotivo">Motivo</label>
                                            <select
                                                className="form-control"
                                                name="idMotivo"
                                                value={atestado.motivoId !== null ? atestado.motivoId : ''}
                                                onChange={handleMotivo}>
                                                <option hidden>Selecione...</option>
                                                {motivo.listaMotivo.map((v, k) => {
                                                    if (atestado.motivoId === v.valor) {
                                                        return <option className="flex-fill" value={v.valor} key={k}
                                                                       selected={true}> {v.texto}</option>;
                                                    } else {
                                                        return <option className="flex-fill" value={v.valor}
                                                                       key={k}> {v.texto}</option>;
                                                    }
                                                })}
                                            </select>
                                            <br/>
                                        </div>
                                        <div className={"col-lg-3"}>
                                            <Input
                                                type="date"
                                                value={dtInicio.current !== null ? dtInicio.current : ""}
                                                onChange={handleDtInicio}
                                                name="dataInicio"
                                                label="Data de Emissão do Atestado"
                                                placeholder="Data e hora"
                                            />
                                        </div>
                                        <div className={"col-lg-2"}>
                                            <Input
                                                type="number"
                                                value={atestado.diasAfastamento}
                                                onChange={handleDiasAfastamento}
                                                name="diasAfastamento"
                                                label="Dias de Afastamento"
                                                placeholder="1"
                                            />
                                        </div>
                                        <div className={"col-lg-3"}>
                                            <Input
                                                type="date"
                                                value={dtTermino.current !== null ? dtTermino.current : ""}
                                                onChange={handleDtFinal}
                                                name="dataFim"
                                                label="Data de Retorno"
                                                disabled={true}
                                            />
                                        </div>
                                        {justificar || atestado.justificativa !== "" ? <Card className={"card"} titulo={"Justificativa"} botaoMin>
                                            <Editor
                                                apiKey='sch2exkkk528sadnq24kffc3nidi73p0g4uma1gw2ubb112y'
                                                onInit={(evt, editor) => editorJustificativa.current = editor}
                                                placeholder={"Insira a justificativa aqui..."}
                                                initialValue={atestado.justificativa}
                                                init={{
                                                    height: 300,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                    currentContent: atestado.justificativa
                                                }}
                                            />
                                        </Card> : null}
                                        <input type="hidden" name="idPessoa"/>
                                        <CID cids={(e) => handleCid(e)} cidsCadastrados={listaDeCidsCadastrados} show={show} colapse={false}/>
                                        <Card className={"card"} titulo={"Descrição"} botaoMin>
                                            <Editor
                                                apiKey='sch2exkkk528sadnq24kffc3nidi73p0g4uma1gw2ubb112y'
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                placeholder={"Insira a descrição aqui..."}
                                                initialValue={atestado.informacoesAdicionais}
                                                init={{
                                                    height: 300,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                    currentContent: atestado.informacoesAdicionais
                                                }}
                                            />
                                        </Card>
                                        <div className="col-lg-3">
                                            <Input
                                                type="text"
                                                value={atestado.nmMedico}
                                                onChange={handleNomeProfissionalSaude}
                                                name="profissionalSaude"
                                                label="Nome do Profissional de Saúde"
                                                placeholder="Digite aqui o nome do profissional de saúde"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <label htmlFor="idEspecialidade">Motivo</label>
                                            <select
                                                className="form-control"
                                                name="idEspecialidade"
                                                value={atestado.especialidade !== null ? atestado.especialidade : ''}
                                                onChange={handleEspecialidade}>
                                                <option hidden>Selecione...</option>
                                                {especialidades.listaEspecialidades.map((v, k) => {
                                                    if (atestado.especialidade === v.valor) {
                                                        return <option className="flex-fill" value={v.valor} key={k}
                                                                       selected={true}> {v.texto}</option>;
                                                    } else {
                                                        return <option className="flex-fill" value={v.valor}
                                                                       key={k}> {v.texto}</option>;
                                                    }
                                                })}
                                            </select>
                                            <br/>
                                        </div>
                                        <div className="col-lg-3">
                                            <Input
                                                type="number"
                                                value={atestado.nrConselho}
                                                onChange={(e) => setAtestado({...atestado, nrConselho: e.target.value})}
                                                name="nrConselho"
                                                label={"Número do Conselho"}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="file-upload">Anexar Atestado</label>
                                            <div>
                                                <button
                                                    className="btn btn-info btn-sm"
                                                    onClick={handleButtonClick}
                                                >
                                                    Escolher arquivo
                                                </button>
                                                <input
                                                    type="file"
                                                    id="file-upload"
                                                    style={{display: 'none'}} // Oculta o campo de entrada de arquivo
                                                    onChange={handleFileChange}
                                                />
                                                <span className="ml-2">{atestado.nmArquivo}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Botao
                        cor={BOTAO.COR.SUCESSO}
                        icone={ICONE.SALVAR}
                        onClick={enviar}
                        disabled={justificar && atestado.justificativa === ""}>
                        Enviar
                    </Botao>
                    <Botao cor={BOTAO.COR.SECUNDARIO} onClick={handleClose}>
                        Fechar
                    </Botao>
                    {/*<ModalFormAlterarConsultorioBloco*/}
                    {/*    corDoBotao={BOTAO.COR.ALERTA}*/}
                    {/*    icone={ICONE.ALTERAR}*/}
                    {/*    titulo={"Alterar"}*/}
                    {/*    nome={"Alterar"}*/}
                    {/*    // verificador={verificador}*/}
                    {/*    // objeto={objeto}*/}
                    {/*    // qtd={qtd}*/}
                    {/*    url={"/hpm/consultorioBloco/alterar/" + props.valor}*/}
                    {/*    funcao={handleClose}*/}
                    {/*/>*/}
                </Modal.Footer>
            </Modal>
        </>
    )
}

EditarAtestado.propTypes = {
    corDoBotao: PropTypes.string,
    icone: PropTypes.string,
    titulo: PropTypes.string,
    nome: PropTypes.string,
    valor: PropTypes.number,
    texto: PropTypes.string,
    funcao: PropTypes.func,
    selecionado: PropTypes.bool,
}