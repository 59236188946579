import {Botao, Card, Input, Pagina, Select} from "../../componentes";
import {BOTAO, HttpVerbo, ICONE, MSG} from "../../util/Constantes";
import React, {useEffect, useRef, useState} from "react";
import CID from "../../componentes/form/CID";
import {ExibirMensagem, xfetch} from "../../util";
import {Editor} from "@tinymce/tinymce-react";

export default function HomologacaoAtestado() {
    const TIPO_ATESTADO = 37;
    const PRAZO = 2;
    const STATUS_ATESTADO_ID = 24;

    const editorRef = useRef(null);
    const editorJustificativa = useRef(null);
    const [dataInicial, setDataInicial] = useState("");
    const [diasAfastamento, setDiasAfastamento] = useState(1);
    const [dataFinal, setDataFinal] = useState("");
    const [atestado, setAtestado] = useState({
        dtEmissaoAtestado: null,
        diasAfastamento: 1,
        dtValidadeAtestado: null,
        motivoId: null,
        informacoesAdicionais: "",
        justificativa: "",
        nmArquivo: "",
        nmMedico: "",
        nrConselho: "",
        cid: "",
        especialidade: null,
        atestadoPdf: null,
        statusAtestadoId: STATUS_ATESTADO_ID,
        usuarioId: localStorage.getItem("id"),
        pacienteId: localStorage.getItem("id")
    });
    const [motivo, setMotivo] = useState({
        idMotivo: null,
        listaMotivo: []
    });
    const [justificar, setJustificar] = useState(false);

    const [descricaoTemporaria, setDescricaoTemporaria] = useState({
        descricao: "",
        justificativa: ""
    });

    const handleDtBloco = (e) => {
        let dtSelecionada = e.target.value;
        setJustificar(false);
        if (dtSelecionada) {
            dtSelecionada = dtSelecionada + "T00:00";
        }
        setDataInicial(e.target.value);
        setAtestado({...atestado, dtEmissaoAtestado: dtSelecionada});
        if (calcularPrazoHomologacao(e.target.value) > PRAZO) {
            setJustificar(true);
        }
    };

    function calcularPrazoHomologacao(dtInicial) {
        const dataAtual = new Date();
        const dataInicial = new Date(dtInicial);
        const diferencaEmMilissegundos = dataAtual.getTime() - dataInicial.getTime();
        return diferencaEmMilissegundos / (1000 * 60 * 60 * 24);
    }

    const handleDtFinal = (e) => {
        let dtSelecionada = e;
        if (dtSelecionada) {
            dtSelecionada = dtSelecionada + "T00:00";
        }
        setAtestado({...atestado, dtValidadeAtestado: dtSelecionada});
    }

    const handleMotivo = (e) => {
        let motivo = e.target.value;
        if (motivo) {
            motivo = parseInt(motivo);
        }
        setAtestado({...atestado, motivoId: motivo});
    }

    const selecionarEspecialidade = (e) => {
        let especialidade = e.value;
        if (especialidade) {
            especialidade = parseInt(especialidade);
        }
        setAtestado({...atestado, especialidade: especialidade});
    }

    const handleDiasAfastamento = (e) => {
        e.preventDefault();
        const dias = parseInt(e.target.value);
        setDiasAfastamento(dias);
        setAtestado(prevAtestado => {
            return {...prevAtestado, diasAfastamento: dias}
        });
    }

    const handleCid = (e) => {
        setAtestado(prevAtestado => {
            return {...prevAtestado, cid: e}
        });
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileName = file.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                const blob = reader.result.split(";base64,").pop();
                setAtestado({...atestado, atestadoPdf: blob, nmArquivo: fileName});
            };
            reader.readAsDataURL(file);
        }
    };

    const handleNomeProfissionalSaude = (e) => {
        const nomePfs = e.target.value;
        setAtestado({...atestado, nmMedico: nomePfs.toUpperCase()});
    }

    const handleJustificativa = () => {
        setAtestado({...atestado, justificativa: editorJustificativa.current ? editorJustificativa.current.getContent({ format: "text" }) : ""});
    }

    const handleReload = () => {
        window.location.reload();
    }

    const enviar = () => {
        try {
            atestado.cid = atestado.cid.join(", ");
        } catch (e) {
            console.error("Erro ao converter CID:", e);
        }

        if (editorRef.current) {
            atestado.informacoesAdicionais = editorRef.current.getContent({
                format: "text",
            });
        }
        if (editorJustificativa.current) {
            atestado.justificativa = editorJustificativa.current.getContent({
                format: "text",
            });
        }
        if (justificar && !atestado.justificativa) {
            ExibirMensagem('Prazo de homologação expirado. Não pode enviar sem cadastrar uma justificativa.', MSG.ALERTA);
        }
        xfetch("/hpm/atestado/cadastrar", atestado, HttpVerbo.POST)
            .then(json => {
                ExibirMensagem('Atestado enviado com sucesso!', MSG.SUCESSO, '', '', '', '', handleReload);
            })
            .catch((error) => {
                console.error('Erro no cadastro.', error);
            });
    }

    const separarDtInicial = () => {
        const dataPartes = dataInicial.split("-");
        const ano = parseInt(dataPartes[0]);
        const mes = parseInt(dataPartes[1]) - 1;
        const dia = parseInt(dataPartes[2]);

        return {ano, mes, dia};
    }

    function calcularDataFinal() {
        const dataSeparada = separarDtInicial();
        const dataSelecionada = new Date(dataSeparada.ano, dataSeparada.mes, dataSeparada.dia);
        let quantidadeDias = parseInt(diasAfastamento);

        if (!isNaN(dataSelecionada.getTime()) && !isNaN(quantidadeDias)) {
            let dataFinalCalculada = new Date(dataSelecionada);
            dataFinalCalculada.setDate(dataFinalCalculada.getDate() + quantidadeDias);
            const dataFormatada = dataFinalCalculada.toISOString().slice(0,10);
            setDataFinal(dataFormatada);
            handleDtFinal(dataFormatada);
        }
    }

    const fetchInterval = 1000 * 60;
    let lastFetchTime = 0;

    const fetchData = () => {
        const currentTime = Date.now();
        const deltaTime = currentTime - lastFetchTime;
        if (deltaTime >= fetchInterval) {
            if (editorRef.current) {
                descricaoTemporaria.descricao = editorRef.current.getContent({
                    format: "text",
                });
            }
            if (editorJustificativa.current){
                descricaoTemporaria.justificativa = editorJustificativa.current.getContent({
                    format: "text",
                });
            }
            xfetch(`/hpm/atestado/alterar`, descricaoTemporaria, HttpVerbo.POST)
                .then((response) => {
                    if (typeof response !== "undefined" ? response.status === "OK" : false) {
                        console.log('Até aqui tudo bem...')
                    }
                })
                .catch(() => console.error('Erro no cadastro.'))
            lastFetchTime = currentTime;
        }
        setTimeout(fetchData, fetchInterval);
    }

    const handleButtonClick = () => {
        document.getElementById("file-upload").click(); // Dispara o clique no input oculto
    };

    const handleTipoAtestado = () => {
        xfetch(`/hpm/tipo/opcoes/${TIPO_ATESTADO}`, {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(motivo => setMotivo({ ...motivo, listaMotivo: motivo.resultado }))
    }

    useEffect(() => {
        handleTipoAtestado();
    }, []);

    useEffect(() => {
        calcularDataFinal();
    }, [diasAfastamento, dataInicial]);

    return (
        <Pagina titulo="Homologar Atestado">
            <div className="row">
                <div className="col-lg-12">
                    <Card titulo="Homologar">
                        <div className="row">
                            <div className="col-lg-4">
                                <label htmlFor="idMotivo">Motivo</label>
                                <select
                                    className="form-control"
                                    name="idMotivo"
                                    value={motivo.idMotivo !== null ? motivo.idMotivo : ''}
                                    onChange={handleMotivo}>
                                    <option hidden>Selecione...</option>
                                    {motivo.listaMotivo.map((v, k) => {
                                        return <option className="flex-fill" value={v.valor} key={k}> {v.texto}</option>
                                    })}
                                </select>
                                <br/>
                            </div>
                            <div className={"col-lg-3"}>
                                <Input
                                    type="date"
                                    value={dataInicial}
                                    onChange={handleDtBloco}
                                    name="dataBloco"
                                    label="Data de Emissão do Atestado"
                                    placeholder="Data e hora"
                                />
                            </div>
                            <div className={"col-lg-2"}>
                                <Input
                                    type="number"
                                    value={diasAfastamento}
                                    onChange={handleDiasAfastamento}
                                    name="diasAfastamento"
                                    label="Dias de Afastamento"
                                    placeholder="1"
                                />
                            </div>
                            <div className={"col-lg-3"}>
                                <Input
                                    type="date"
                                    value={dataFinal !== undefined ? dataFinal : ""}
                                    name="dataFinal"
                                    label="Data de Retorno"
                                    disabled={true}
                                />
                            </div>
                            {justificar ? <Card className={"card"} titulo={"Justificativa"} botaoMin>
                                <Editor
                                    apiKey='sch2exkkk528sadnq24kffc3nidi73p0g4uma1gw2ubb112y'
                                    onInit={(evt, editor) => editorJustificativa.current = editor}
                                    placeholder={"Insira a justificativa aqui..."}
                                    onChange={handleJustificativa}
                                    init={{
                                        height: 300,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </Card> : null}
                            <input type="hidden" name="idPessoa"/>
                            <CID cids={(e) => handleCid(e)} colapse={false}/>
                            <Card className={"card"} titulo={"Descrição"} botaoMin>
                                <Editor
                                    apiKey='sch2exkkk528sadnq24kffc3nidi73p0g4uma1gw2ubb112y'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    placeholder={"Insira a descrição aqui..."}
                                    init={{
                                        height: 300,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </Card>
                            <div className="col-lg-3">
                                <Input
                                    type="text"
                                    value={atestado.nmMedico}
                                    onChange={handleNomeProfissionalSaude}
                                    name="profissionalSaude"
                                    label="Nome do Profissional de Saúde"
                                    placeholder="Digite aqui o nome do profissional de saúde"
                                />
                            </div>
                            <div className="form-group col-lg-3">
                                <label htmlFor="idEspecialidade">Especialidade</label>
                                <Select
                                    funcao={selecionarEspecialidade}
                                    nome="idEspecialidade"
                                    url={"/hpm/especialidade/opcoes"}
                                />
                            </div>
                            <div className="col-lg-3">
                                <Input
                                    type="number"
                                    value={atestado.nrConselho}
                                    onChange={(e) => setAtestado({...atestado, nrConselho: e.target.value})}
                                    name="nrConselho"
                                    label={"Número do Conselho"}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="file-upload">Anexar Atestado</label>
                                <div>
                                    <button
                                        className="btn btn-info btn-sm"
                                        onClick={handleButtonClick}
                                    >
                                        Escolher arquivo
                                    </button>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        style={{display: 'none'}} // Oculta o campo de entrada de arquivo
                                        onChange={handleFileChange}
                                    />
                                    <span className="ml-2">{atestado.nmArquivo}</span>
                                </div>
                            </div>
                            <div className="col-lg-12 text-lg-center mt-4 mb-4">
                                <Botao
                                    cor={BOTAO.COR.SUCESSO}
                                    icone={ICONE.SALVAR}
                                    onClick={enviar}
                                    disabled={justificar && atestado.justificativa === ""}>
                                    Enviar
                                </Botao>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Pagina>
    )
}