import React from "react";

const BotaoScrollTop = () => {
  return (
    <a id='back-to-top' href='#' className='btn btn-primary btn back-to-top' role='button'>
      <i className='fas fa-chevron-up'></i>
    </a>
  );
};

export default BotaoScrollTop;








