import {Botao, BotaoPesquisar, Card, Input, Pagina, Tabela} from "../../componentes";
import {BOTAO, HttpVerbo, ICONE, MSG} from "../../util/Constantes";
import React, {useEffect, useState} from "react";
import {ExibirMensagem, xfetch} from "../../util";
import EditarAtestado from "../editar/EditarAtestado";
import {Modal} from "react-bootstrap";

export default function MeusAtestados() {
    const SIZE = 5;
    const STATUS_ATESTADO_OBJ = 37;
    const [pesquisado, setPesquisado] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [listaAtestados, setListaAtestados] = useState({
        atestados: [
            {
                id: "",
                motivo: {
                    nome: ""
                },
                dtEmissaoAtestado: "",
                diasAfastamento: "",
                nmMedico: "",
            }
        ]
    });
    const [status, setStatus] = useState({
        listarStatus: []
    });
    const [pesquisarAtestados, setPesquisarAtestados] = useState({
        pacienteId: localStorage.getItem("id"),
        dataInicio: null,
        dataFim: null,
        nmMedico: null,
        statusAtestadoId: null
    });
    const [atestadoId, setAtestadoId] = useState({
        id: "",
        atestado: {}
    });

    const handleBtnExcluir = (atestadoId, objeto) => {
        setAtestadoId({ id: atestadoId, atestado: objeto });
        setShowModal(true);
    }

    const handleConfirmacao = async () => {
        try {
            await xfetch(`/hpm/atestado/excluir/${atestadoId.id}`, atestadoId.atestado, HttpVerbo.PUT);
            ExibirMensagem("Atestado Excluído!", MSG.SUCESSO);
            setPesquisado(!pesquisado);
        } catch (error) {
            ExibirMensagem(error.message || "Erro ao cancelar o atestado.", MSG.ERRO);
        } finally {
            setShowModal(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDtInicial = (e) => {
        const dtInicial = e.target.value + "T00:00";
        setPesquisarAtestados({ ...pesquisarAtestados, dataInicio: dtInicial });
    }

    const handleDtFim = (e) => {
        const dtFim =  e.target.value + "T23:59";
        setPesquisarAtestados({ ...pesquisarAtestados, dataFim: dtFim });
    }

    const handleNmPfs = (e) => {
        setPesquisarAtestados({ ...pesquisarAtestados, nmMedico: e.target.value });
    }

    const handleStatus = (e) => {
        setPesquisarAtestados({ ...pesquisarAtestados, statusAtestadoId: e.target.value });
    }

    const handleListarAtestados = () => {
        if (pesquisarAtestados.dataInicio === null ||
            pesquisarAtestados.dataFim === null) {
            return;
        }
        xfetch(`/hpm/atestado/atestados/${0}/${999}`, pesquisarAtestados, HttpVerbo.POST)
            // .then(response => response.json())
            .then((atestado) => {
                // atestado.content vem do endpoint que é uma paginação
                setListaAtestados({ ...listaAtestados, atestados: atestado.content });
            })
        return listaAtestados;
    }

    const listarStatus = () => {
        xfetch(`/hpm/status/${STATUS_ATESTADO_OBJ}`, {}, HttpVerbo.GET)
            .then( res => res.json())
            .then(status => setStatus({...status, listaStatus: status.resultado}))
    }

    const enviar = () => {
        handleListarAtestados();
        setPesquisado(!pesquisado);
    }

    useEffect(() => {
        listarStatus();
    }, []);

    useEffect(() => {
        handleListarAtestados();
    }, [pesquisado]);

    const colunas = [
        { text: "Motivo" },
        { text: "Data Emissão" },
        { text: "Dias de Afastamento" },
        { text: "Nome do Profissional de Saúde" },
        { text: "Ação" }
    ]

    const dados = () => {
        return (
            typeof listaAtestados.atestados !== "undefined" ? listaAtestados.atestados.map((atestado) => {
                const dataEmissao = new Date(atestado.dtEmissaoAtestado).toLocaleDateString('pt-BR');
                return ({
                    'motivo': atestado.motivo.nome,
                    'data_emissao': dataEmissao !== "Invalid Date" ? dataEmissao : null,
                    'dias_de_afastamento': atestado.diasAfastamento,
                    'nome_do_profissional_de_saude': atestado.nmMedico,
                    'acao':
                        <div>
                            <Botao cor={BOTAO.COR.PERIGO} onClick={() => handleBtnExcluir(atestado.id, atestado)} value={atestado.id}>Excluir</Botao>
                            <EditarAtestado
                                corDoBotao={BOTAO.COR.ALERTA}
                                icone={ICONE.ALTERAR}
                                titulo={"Editar"}
                                nome={"Editar"}
                                valor={+atestado.id}
                                funcao={handleListarAtestados}
                            />
                        </div>
                })
            }) : null
        )
    }

    return (
        <Pagina titulo={"Meus Atestados"}>
            <div className="row">
                <div className="col-lg-12">
                    <Card titulo="Ver Meus Atestados">
                        <div className="row">
                            <div className={"col-lg-3"}>
                                <Input
                                    type="date"
                                    onChange={(e) => handleDtInicial(e)}
                                    name="dataInicial"
                                    label="Data Inicial"
                                />
                            </div>
                            <div className={"col-lg-3"}>
                                <Input
                                    type="date"
                                    onChange={(e) => handleDtFim(e)}
                                    name="dataFim"
                                    label="Data Validade"
                                />
                            </div>
                            <div className={"col-lg-3"}>
                                <Input
                                    type="text"
                                    value={pesquisarAtestados.nmMedico !== null ? pesquisarAtestados.nmMedico : ""}
                                    onChange={(e) => handleNmPfs(e)}
                                    name="nmMedico"
                                    label="Nome do Profissional de Saúde"
                                    placeholder="Digite aqui o nome do profissional de saúde"
                                />
                            </div>
                            <div className="col-lg-3">
                                <label>Status</label>
                                <select
                                    className="form-control"
                                    name="idStatus"
                                    value={status.idStatus !== null ? status.idStatus : ""}
                                    onChange={handleStatus}>
                                    <option hidden>Selecione...</option>
                                    {typeof status.listaStatus !== "undefined" ? status.listaStatus.map((v, k) => {
                                        if (v.id !== 15) {
                                            return <option className="flex-fill" value={v.id} key={k}> {v.nome}</option>
                                        }
                                    }) : ""}
                                </select>
                            </div>
                            <div className="col-lg-12 text-lg-right mt-4 mb-4">
                                <BotaoPesquisar onClick={enviar}/>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <Card titulo={"Lista dos meus atestados"}>
                <Tabela colunas={colunas} dados={dados()} pageSize={SIZE}/>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>Excluir</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Deseja realmente excluir o atestado?</Modal.Body>
                    <Modal.Footer>
                        <Botao variant="secondary" onClick={handleCloseModal}>
                            Fechar
                        </Botao>
                        <Botao variant="primary" onClick={handleConfirmacao}>
                            Confirmar
                        </Botao>
                    </Modal.Footer>
                </Modal>
            </Card>
        </Pagina>
    )
}