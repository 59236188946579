import { BOTAO, HttpVerbo, MSG } from "../../util/Constantes";
import { Botao } from "../Botao";
import { Modal } from "react-bootstrap";
import { Input } from "../form";
import { ExibirMensagem, xfetch } from "../../util";

export const ModalConfirmaConsulta = ({
  show,
  enviar,
  handleClose,
  dadosPaciente,
  setDadosPaciente,
}) => {
  const objeto = {
    id: dadosPaciente.id,
    celular: dadosPaciente.contato,
    email: dadosPaciente.email,
  };

  console.log(dadosPaciente)

  function alterarCelular(e) {
    const celular = e.target.value;
    setDadosPaciente({ ...dadosPaciente, contato: celular });
  }

  function alterarEmail(e) {
    const email = e.target.value;

    setDadosPaciente({ ...dadosPaciente, email: email });
  }

  async function handleAlterar() {
    try {
      const json = await xfetch("/hpm/pessoa/alterar", objeto, HttpVerbo.PUT);
      if (json.status === "OK") {
      } else {
        throw new Error("Falha ao alterar os dados");
      }
    } catch (error) {
      console.error("Erro ao alterar os dados:", error);
      throw error;
    }
  }

  async function handleConfirma() {
    try {
      if (!dadosPaciente.contato || !dadosPaciente.email) {
        ExibirMensagem("Preencha todos os campos!", MSG.ALERTA);
        return;
      }

      const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      const regexContato = /^\d{11}$/;

      if (!regexEmail.test(dadosPaciente.email)) {
        ExibirMensagem("Email no formato inválido!", MSG.ALERTA);
        return;
      }

      // Validação do telefone
      if (!regexContato.test(dadosPaciente.contato)) {
        ExibirMensagem("Telefone no formato inválido! Digite apenas numeros", MSG.ALERTA);
        return;
      }
      handleClose();
      await handleAlterar();
      await enviar();
    } catch (error) {
      console.error("Erro ao confirmar:", error);
    }
  }

  return (
    <Modal show={show} onHide={handleClose} fullscreen="md-down">
      <Modal.Header>
        <Modal.Title>Confirme seu email e telefone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          label="Email:"
          value={dadosPaciente.email}
          onChange={alterarEmail}
        />
        <Input
          label="Contato:"
          value={dadosPaciente.contato}
          onChange={alterarCelular}
        />
      </Modal.Body>
      <Modal.Footer>
        <Botao cor={BOTAO.COR.ALERTA} onClick={handleClose}>
          Fechar
        </Botao>
        <Botao cor={BOTAO.COR.PRIMARIO} onClick={handleConfirma}>
          Confirmar e Agendar
        </Botao>
      </Modal.Footer>
    </Modal>
  );
};
