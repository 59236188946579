import React, {useEffect, useState} from "react";
import {AutocompletarCid} from "./AutocompletarCid";
import {FormGroup} from "react-bootstrap";
import {BotaoExcluir} from "../Botao";
import {Card} from "../card/Card";

export default function CID(props) {
    const [consulta, setConsulta] = useState({
        idCids: [],
    });

    const [cids, setCids] = useState([]);

    const colapse = props.colapse;

    const handleCidsCadastrados = () => {
        setConsulta(prevConsulta => {
            return {...prevConsulta, idCids: []};
        });
        setCids([]);
        props.cidsCadastrados.map(cid => {
            setConsulta(prevConsulta => {
                return {...prevConsulta, idCids: [...prevConsulta.idCids, cid]};
            });
            setCids(prevCids => {
                return [...prevCids, cid];
            });
        });
    }

    useEffect(() => {
        props.cids(consulta.idCids);
    }, [consulta.idCids]);

    const handleCID = () => {
        const idCid = document.getElementById("idcid").value;
        const idCidNome = document.getElementById("idcidAuto").value;
        let codigoCid = idCidNome.split(" - ");
        setConsulta(prevConsulta => {
            return {...prevConsulta, idCids: [...prevConsulta.idCids, codigoCid[0]]}
        });
        setCids(prevCids => {
            return [...prevCids, codigoCid[0]]
        });
        document.getElementById("idcidAuto").value = "";
    };

    const handleRemoveCid = (position) => {
        setConsulta({
            ...consulta,
            idCids: [...consulta.idCids.filter((_, index) => index !== position)],
        });
        setCids([...cids.filter((_, index) => index !== position)]);
    };

    useEffect(() => {
        if (props.cidsCadastrados && props.cidsCadastrados.length > 0) {
            handleCidsCadastrados();
        }
    }, [props.cidsCadastrados]);

    return (
        <Card className={colapse ? "collapsed-card" : "card"} titulo={"CID"} botaoMin>
            <AutocompletarCid
                name="cid"
                url={"/hpm/cid/"}
                label="Digite o CID:"
                placeholder="Nome ou código aqui"
                tamanho={3}
                retorno={handleCID}
                required
            />
            <br/>
            <FormGroup>
                <div className={"col-lg-12"}>
                    {cids.map((cid, index) => (
                        <FormGroup
                            key={index}
                            className={"col-lg-3"}
                            style={{display: "inline-flex"}}
                        >
                            <div
                                className={"info-box col-lg-12"}
                                style={{display: "flex"}}
                            >
                                <div key={index} className="info-box-content">
                                    <span className="info-box-text">CID</span>
                                    <span className="info-box-text">{cid}</span>
                                </div>
                                <BotaoExcluir
                                    style={{marginLeft: "1em"}}
                                    onClick={() => {
                                        handleRemoveCid(index);
                                    }}
                                />
                            </div>
                        </FormGroup>
                    ))}
                </div>
            </FormGroup>
        </Card>
    )
}
