import { HttpVerbo, MSG } from "./Constantes";
import { ExibirMensagem } from "./ExibirMensagem";

const uuid = () => {
    let d = new Date().getTime();
    let d2 = (performance?.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;
        if(d > 0){
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : ((r && 0x3) || 0x8)).toString(16);
    });
}

const erro = (e) => {
    switch (e.status) {
        case 500: {
            Promise.resolve(e.json()).then(dados => ExibirMensagem(dados.message, MSG.ERRO))
            return
        }

        case 400: {
            Promise.resolve(e.json()).then(dados => ExibirMensagem(dados.message, MSG.ALERTA))
            return
        }

        case 404: {
            ExibirMensagem("Página não encontrada", MSG.ALERTA)
            return
        }

        case 403: {
            ExibirMensagem("Você não possui acesso a esse recurso", MSG.ERRO)
            return
        }
        default: return e.json()
    }
}

function ExcecaoNegocio(message) {
    this.message = message;
    this.name = "ExcecaoNegocio";
}

export const xfetch = async (endpoint, dados, verbo = HttpVerbo.GET) => {

    console.log(process.env.NODE_ENV)

    let servidor = process.env.REACT_APP_BACKEND;

    let myHeaders = new Headers();
    let idTransacao = uuid();
    let idPessoaLogada = localStorage.getItem('id');
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("idTransacao", idTransacao);
    myHeaders.append("idPessoaLogada", idPessoaLogada);
    if (sessionStorage.getItem('token')) {
        myHeaders.append("token", sessionStorage.getItem('token'));
    }

    const myInit = { method: verbo,
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'};

    if (verbo === HttpVerbo.POST || verbo === HttpVerbo.PUT) {
         try {
            const e = await fetch(servidor + endpoint, {
                headers: myHeaders,
                method: verbo,
                body: JSON.stringify(dados)
            });
            return erro(e);
        } catch (e_1) {
            return erro(e_1, idTransacao);
        }
    } else {
        try {
            return await fetch(servidor + endpoint, myInit);
        } catch (e_2) {
            return erro(e_2, idTransacao);
        }
    }
}

export function Logado () {
    return sessionStorage.getItem('token');
}

export function Ambiente() {
    let servidor = process.env.REACT_APP_BACKEND;
    if (servidor.includes('http://localhost:8080')) {
        return 'dev - HPM';
    }
    return 'prod - HPM';
}

export function ValidaToken () {
    const tokenJson = {
        token: sessionStorage.getItem('token')
    }
    xfetch('/validaToken',tokenJson, HttpVerbo.POST)
        .then(json => {
            if (!json.resultado) {
                sessionStorage.clear();
                window.location.replace('/login')
            }
        })
}

export const Logoff = () => {
    window.onunload = ((e) => {
        e.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
    });
}

export const temPermissao = (acao) => {
    let perfis = localStorage.getItem('perfis');
    if (perfis) {        
        return perfis.includes(acao);
    }
    return window.location.replace('/login');
}
