import React, { useEffect, useState } from "react";
import { Botao, BotaoSalvar, Card, Input, Pagina, Select, Tabela } from "../../componentes";
import { ExibirMensagem, xfetch } from "../../util";
import {BOTAO, HttpVerbo, ICONE, MESES, MSG} from "../../util/Constantes";
import EditarConsultorioBloco from '../editar/EditarConsultorioBloco';
import ModalFormExcluir from "../../componentes/modal/ModalFormExcluir";

export default function ConsultorioBlocoJunta() {
    const [selecionar, setSelecionar] = useState(false);

    const [lista, setLista] = useState({
        blocos: [
            {
                id: "",
                nmEscala: "",
                nmEspecialidade: "",
                nmPiso: "",
                dtInicio: "",
                dtTermino: "",
                qtConsultas: "",
                qtEmergencias: "",
            }
        ]
    });

    const [objeto, setObjeto] = useState(
        {
            dataInicio: null,
            dataTermino: null,
            idEscala: null,
            idEspecialidade: null,
            idProfissionalSaude: null,
            idSala: null,
            qtdConsultas: null,
            qtdEmergencias: 4
        }
    )

    const [profissionais, setProfissionais] = useState({});
    const [escala, setEscala] = useState({});
    const [status, setStatus] = useState({
        listaStatus: []
    });
    const [tamanhoColuna, setTamanhoColuna] = useState({
        colunaQtdConsulta: "col-lg-6",
        colunaEspecialidade: "col-lg-6"
    });

    const escalaObjeto = 31;
    const tipoEscalaCurso = 598;

    const [verificador, setVerificador] = useState({
        ano: 0,
        mesInicio: 0,
        mesTermino: 0,
        mesEscala: 0,
        anoEscala: 0
    });

    let [mes, ano] = "";
    let dt = "";

    const handleDtHrInicio = (e) => {
        dt = e.target.value;
        let mesVerificador = dt.split("-");
        verificador.mesInicio = Number(mesVerificador[1]);
        verificador.ano = Number(mesVerificador[0]);
        setObjeto({...objeto, dataInicio: dt});
    }

    const handleDtHrTermino = (e) => {
        dt = e.target.value;
        let mesVerificador = dt.split("-");
        verificador.mesTermino = Number(mesVerificador[1]);
        verificador.ano = Number(mesVerificador[0]);
        setObjeto({...objeto, dataTermino: dt});
    }

    const handleQtdConsulta = (e) => {
        e.preventDefault()
        setObjeto({...objeto, qtdConsultas: Number(e.target.value)})
    }

    const quantidadeConsultas =
        <div className={tamanhoColuna.colunaQtdConsulta}>
            <Input
                type="text"
                onChange={handleQtdConsulta}
                value={objeto.qtdConsultas !== null ? objeto.qtdConsultas : ''}
                name="qtdConsultas"
                label="Quantidade de Consultas"
                placeholder="Qtd consultas"/>
        </div>;

    const handleStatus = (e) => {
        const statusId = e.target.value;
        status.idStatus = Number(statusId);
        listarEscalaPorStatus();
    }

    const listarEscalaPorStatus = (e) => {
        xfetch('/hpm/escala/junta/' + status.idStatus + '/opcoes', {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(json => {
                    setEscala({...escala, escalas: json.resultado});
                }
            )
    }

    const selecionarEscala = (e) => {
        objeto.idEscala = Number(e.target.value);
        const nomeEscala = escala.escalas.filter(escala => escala.valor === objeto.idEscala);
        [mes, ano] = nomeEscala[0].nome.split(" - ");
        verificador.mesEscala = MESES.indexOf(mes) + 1;
        verificador.anoEscala = Number(ano);
        objeto.idTipo = nomeEscala[0].idTipoEscala;
        if (objeto.idTipo === tipoEscalaCurso) {
            setTamanhoColuna({...tamanhoColuna, colunaQtdConsulta: "col-lg-3"});
        }
        setSelecionar(!selecionar);
    }

    const selecionarEspecialidade = (e) => {
        objeto.idEspecialidade = Number(e.value)
        setTamanhoColuna({...tamanhoColuna, colunaEspecialidade: "col-lg-3"})
        listarProfissionalPorEspecialidade();
    }

    const selecionarProfissionalSaude = (e) => {
        objeto.idProfissionalSaude = Number(e.target.value);
    }

    const selecionarSala = (e) => {
        objeto.idSala = e.value;
    }

    const listarProfissionalPorEspecialidade = () => {
        xfetch('/hpm/profissionalSaude/' + objeto.idEspecialidade + '/opcoes', {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(json => {
                setProfissionais({ ...profissionais, profissionais: json.resultado });
            })
    }

    const enviar = (e) => {
        if (objeto.qtdEmergencias < 4) {
            ExibirMensagem("Número de encaixes deve ser maior ou igual a 4", MSG.ALERTA);
            return; // Evita a execução do restante do código se o valor for menor que 4
        }
        if (verificador.mesInicio === verificador.mesEscala &&
            verificador.mesTermino === verificador.mesEscala &&
            verificador.ano === verificador.anoEscala) {
            xfetch('/hpm/consultorioBloco/cadastrar', objeto, HttpVerbo.POST)
                .then(json => {
                        if (typeof json !== "undefined" ? json.status === "OK" : false) {
                            ExibirMensagem('Consultorio Bloco Cadastrado Com Sucesso!', MSG.SUCESSO, '', '', '', '', handleCadastro());
                        }
                    }
                )
        } else {
            ExibirMensagem("Escala selecionada não pode ser diferente do mês de início e término da escala!", MSG.ALERTA);
        }
    }

    const listaStatusEscala = () => {
        xfetch('/hpm/status/' + escalaObjeto, {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(status => setStatus({...status, listaStatus: status.resultado}))
    }

    const handleCadastro = () => {
        if (objeto.idEscala !== null) {
            xfetch('/hpm/consultorioBloco/escala/' + objeto.idEscala + '/opcoes', {}, HttpVerbo.POST)
                .then(lista => setLista({...lista, blocos: lista.resultado}))
        }
        return lista;
    }

    useEffect(() => {
        handleCadastro();
        listaStatusEscala();
    }, [selecionar])

    const colunas = [
        {text: "Escala"},
        {text: "Nome"},
        {text: "Especialidade"},
        {text: "Sala"},
        {text: "Data Início"},
        {text: "Data Término"},
        {text: "Consultas"},
        {text: "Encaixes"},
        {text: "Ação"}
    ]

    const dados = () => {
        return (
            lista.blocos.map((bloco) => {
                return ({
                    'escala': bloco.texto8,
                    'nome': bloco.texto,
                    'especialidade': bloco.texto2,
                    'sala': bloco.texto7, // Inserir o nome completo
                    'data_inicio': bloco.texto3,
                    'data_termino': bloco.texto4,
                    'consultas': bloco.texto5,
                    'encaixes': bloco.texto6,
                    'acao': bloco.id !== "" ? <div>
                        <ModalFormExcluir
                            corDoBotao={BOTAO.COR.PERIGO}
                            icone={ICONE.EXCLUIR}
                            nome={"Excluir"}
                            titulo={"Excluir"}
                            funcao={handleCadastro}
                            objeto={bloco}
                            url={`/hpm/consultorioBloco/excluir/${bloco.valor}`}
                        />
                        <EditarConsultorioBloco
                            corDoBotao={BOTAO.COR.ALERTA}
                            icone={ICONE.EDITAR}
                            titulo={"Editar"}
                            nome={"Editar"}
                            valor={bloco.valor}
                            funcao={handleCadastro}
                        />
                    </div> : ""
                })
            })
        )
    }

    const selectEspecialista =
        objeto.idEspecialidade ? <div className={tamanhoColuna.colunaEspecialidade}>
            <label>Profissional</label>
            <select
                className="form-control"
                onChange={selecionarProfissionalSaude}
                name="idProfissionalSaude">
                <option hidden>Selecione...</option>
                {typeof profissionais.profissionais !== "undefined" ? profissionais.profissionais.map((v, k) => {
                    return <option className="flex-fill" value={v.valor} key={k}> {v.texto}</option>
                }) : ''}
            </select>
        </div> : "";

    return (
        <Pagina titulo="Cadastrar Consultorio Bloco - JISM">
            <div className="row">
                <div className="col-lg-12">
                    <Card titulo="Cadastrar">
                        <div className="row">
                            <div className="col-lg-3">
                                <label>Status da Escala</label>
                                <select
                                    className="form-control"
                                    name="idStatus"
                                    value={status.idStatus !== null ? status.idStatus : ''}
                                    onChange={handleStatus}>
                                    <option hidden>Selecione...</option>
                                    {status.listaStatus.map((v, k) => {
                                        if (v.id !== 15) {
                                            return <option className="flex-fill" value={v.id} key={k}> {v.nome}</option>
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="col-lg-3">
                                <label>Escala</label>
                                <select
                                    className="form-control"
                                    onChange={selecionarEscala}
                                    name="idEscala">
                                    <option hidden>Selecione...</option>
                                    {typeof escala.escalas !== "undefined" ? escala.escalas.map((v, k) => {
                                        return <option className="flex-fill" value={v.valor}
                                                       key={k}> {v.nome} - {v.nmTipoEscala}</option>
                                    }) : ''}
                                </select>
                            </div>
                            <div className={tamanhoColuna.colunaEspecialidade}>
                                <label>Especialidade</label>
                                <Select
                                    funcao={selecionarEspecialidade}
                                    nome="idEspecialidade"
                                    url={"/hpm/especialidade/naoExcluidas"}/>
                            </div>
                            {selectEspecialista}
                            <div className={tamanhoColuna.colunaQtdConsulta}>
                                <label>Prédio - Piso - Sala</label>
                                <Select
                                    funcao={selecionarSala}
                                    nome="idSala"
                                    url={"/hpm/sala/opcoes"}/>
                            </div>
                            <div className="col-lg-3">
                                <Input
                                    type="datetime-local"
                                    value={objeto.dataInicio !== null ? objeto.dataInicio : ''}
                                    onChange={handleDtHrInicio}
                                    name="dataInicio"
                                    label="Data e hora início"
                                    placeholder="Data e hora"/>
                            </div>
                            <div className="col-lg-3">
                                <Input
                                    type="datetime-local"
                                    value={objeto.dataTermino !== null ? objeto.dataTermino : ''}
                                    onChange={handleDtHrTermino}
                                    name="dataTermino"
                                    label="Data e hora término"
                                    placeholder="Data e hora"/>
                            </div>
                            {objeto.idTipo === tipoEscalaCurso ? quantidadeConsultas : ''}
                        </div>
                        <div className="col-lg-15 text-lg-right mt-4 mb-4">
                            <BotaoSalvar onClick={enviar}/>
                        </div>
                    </Card>
                    <Card titulo="Consultórios Cadastrados">
                        <Tabela colunas={colunas} dados={dados()} pageSize={5}/>
                    </Card>
                </div>
            </div>
        </Pagina>
    )
}