import React from "react";

export const Input = ({ label, legenda, className, ...props }) => {
    const small = legenda && <small className="text-danger">{legenda}</small>;

    return (
        <div className={`form-group ${className}`}>
            <label>{label}</label>
            <input autoComplete="off" className="form-control" {...props} />
            {small}
        </div>
    );
};

export default Input;
