import PropTypes from "prop-types";
import React, {useRef, useState} from "react";
import { ExibirMensagem, xfetch } from '../../util';
import { HttpVerbo, MSG } from "../../util/Constantes";
import { BotaoAlterar } from "../Botao";
import { ModalFormCancelamento } from "./ModalFormCancelamento";

export default function ModalFormExcluir(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleExcluir = () => {
        enviar();
        setShow(false);
    }

    const enviar = () => {
        xfetch(props.url, props.objeto, HttpVerbo.PUT)
            .then( json => {
                    if (typeof json !== "undefined" ? json.status === "OK" : false) {
                        ExibirMensagem('Consultorio Bloco Excluído com Sucesso!', MSG.SUCESSO, '', '', '', '', props.funcao());
                    }
                }
            )
            .catch(error => console.log(error))
    }

    return (
        <>
            <BotaoAlterar cor={props.corDoBotao} icone={props.icone} onClick={handleShow}>{props.nome}</BotaoAlterar>
            <ModalFormCancelamento
                show={show}
                onHide={handleClose}
                titulo={props.titulo}
                body="Deseja realmente excluir?"
                handleClose={handleClose}
                handleOpen={handleExcluir}
            />
        </>
    )
}

ModalFormExcluir.propTypes = {
    verificador: PropTypes.object,
    objeto: PropTypes.object,
    qtd: PropTypes.object,
    corDoBotao: PropTypes.string,
    icone: PropTypes.string,
    titulo: PropTypes.string,
    nome: PropTypes.string,
    texto: PropTypes.string,
    url: PropTypes.string,
}