import PropTypes from "prop-types";
import React, {useState} from "react";
import {ExibirMensagem, xfetch} from '../../util';
import {MSG} from "../../util/Constantes";
import {BotaoAlterar} from "../Botao";
import {ModalFormCancelamento} from "./ModalFormCancelamento";

export default function ModalFormAlterar(props) {
    const [show, setShow] = useState(false);

    const objeto = {
        nome: props.escala.nome,
        dataInicio: props.escala.dataInicio,
        dataTermino: props.escala.dataTermino,
        idStatus: props.statusId,
        tipoEscala: props.escala.idTipoEscala
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAlterar = () => {
        enviar();
        setShow(false);
    }

    const enviar = () => {
        xfetch(props.url, objeto, props.verbo)
            .then( json => {
                    if (typeof json !== "undefined" ? json.status === "OK" : false) {
                        ExibirMensagem(props.texto + " " + props.msg, MSG.SUCESSO, '', '', '', '', props.funcao());
                    }
                }
            )
            .catch(error => console.log(error))
    }

    return (
        <>
            <BotaoAlterar cor={props.corDoBotao} icone={props.icone} onClick={handleShow}>{props.nome}</BotaoAlterar>
            <ModalFormCancelamento
                show={show}
                onHide={handleClose}
                titulo={props.titulo}
                body="Deseja realmente alterar?"
                handleClose={handleClose}
                handleOpen={handleAlterar}
            />
        </>
    )
}

ModalFormAlterar.propTypes = {
    escala: PropTypes.object,
    statusId: PropTypes.number,
    corDoBotao: PropTypes.string,
    icone: PropTypes.string,
    titulo: PropTypes.string,
    nome: PropTypes.string,
    texto: PropTypes.string,
    msg: PropTypes.string,
    verbo: PropTypes.string,
    url: PropTypes.string,
}