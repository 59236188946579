import React from "react";
import { Card, Pagina } from "../componentes";
import { Link } from "react-router-dom";

export default function Principal() {
  return (
    <Pagina titulo="Bem Vindo">
      <Card className="">
        <div class="mb-4">
          Ações Rápidas
        </div>
        <Link
          to="/agendar/consulta"
        >
          <Card className="col-lg-4 col-md-5 bg-gradient-primary" style={{ height: '14rem'}}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <i className="far fa-calendar-alt pb-2" aria-hidden="true" style={{ fontSize: '1.5rem' }}></i>
              <h5 className="text-center">Agendar Consulta</h5>
            </div>
          </Card>
        </Link>
      </Card>
    </Pagina>
  );
}
