import {Botao, Card, Pagina, Select, Tabela} from "../../componentes";
import React, {useState, useEffect} from "react";
import {BOTAO, HttpVerbo, ICONE, MSG} from "../../util/Constantes";
import {ExibirMensagem, xfetch} from "../../util";
import {handleBtnAlterarStatus} from "../../util/Util";
import ModalFormAlterar from "../../componentes/modal/ModalFormAlterar";
import ModalFormExcluir from "../../componentes/modal/ModalFormExcluir";

export default function ListarEscalas() {
    const [objeto, setObjeto] = useState({});

    const [escala, setEscala] = useState({
        escalas: []
    });

    const [status, setStatus] = useState({
        listaStatus: []
    });

    let escalaObjeto = 31;
    let msg = "";

    const handleStatus = (e) => {
        objeto.idStatus = e.target.value;
        setObjeto({...objeto, idStatus: e.target.value});
        listarEscalasPorStatus();
    }

    const listarEscalasPorStatus = () => {
        xfetch(`/hpm/escala/${objeto.idStatus}/opcoes`, {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(escala => setEscala({...escala, escalas: escala.resultado}))
    }

    useEffect(() => {
        xfetch('/hpm/status/' + escalaObjeto, {}, HttpVerbo.GET)
            .then( res => res.json())
            .then(status => setStatus({...status, listaStatus: status.resultado}))
    }, [])

    const colunas = [
        {text: "Nome"},
        {text: "Data Início"},
        {text: "Data Término"},
        {text: "Situação"},
        {text: "Ações"}
    ]

    const dados = () => {
        if(typeof(escala.escalas) !== "undefined") {
            return(
                escala.escalas.map((escala) => {
                    const urlEscala = `/hpm/escala/alterar/${escala.valor}`;
                    let statusId = "";
                    let btnAlteracaoStatus = "";
                    if (escala.idStatus === 13) {
                        statusId = 14;
                        msg = "Inativada!";
                        btnAlteracaoStatus = <div id={"btnAlteracao"}>
                            <ModalFormAlterar
                                escala={escala}
                                statusId={statusId}
                                corDoBotao={BOTAO.COR.PRIMARIO}
                                icone={ICONE.INFO}
                                titulo={"Inativar"}
                                nome={"Inativar"}
                                texto={"Escala"}
                                msg={msg}
                                verbo={HttpVerbo.PUT}
                                url={urlEscala}
                                funcao={listarEscalasPorStatus}
                            />
                            <ModalFormExcluir
                                corDoBotao={BOTAO.COR.PERIGO}
                                icone={ICONE.EXCLUIR}
                                nome={"Excluir"}
                                titulo={"Excluir"}
                                funcao={listarEscalasPorStatus}
                                objeto={objeto}
                                url={`/hpm/escala/excluir/${escala.valor}`}
                            />
                        </div>;
                    }
                    if (escala.idStatus === 14) {
                        statusId = 15;
                        let statusAtivar = 13;
                        msg = "Finalizada!";
                        btnAlteracaoStatus = <div id={"btnAlteracao"}>
                            <ModalFormAlterar
                                escala={escala}
                                statusId={statusAtivar}
                                corDoBotao={BOTAO.COR.SUCESSO}
                                icone={ICONE.INFO}
                                titulo={"Ativar"}
                                nome={"Ativar"}
                                texto={"Escala"}
                                msg={"Ativada"}
                                verbo={HttpVerbo.PUT}
                                url={urlEscala}
                                funcao={listarEscalasPorStatus}
                            />
                            <ModalFormAlterar
                                escala={escala}
                                statusId={statusId}
                                corDoBotao={BOTAO.COR.ALERTA}
                                icone={ICONE.ENVIAR}
                                titulo={"Finalizar"}
                                nome={"Finalizar"}
                                texto={"Escala"}
                                msg={msg}
                                verbo={HttpVerbo.PUT}
                                url={urlEscala}
                                funcao={listarEscalasPorStatus}
                            />
                            <ModalFormExcluir
                                corDoBotao={BOTAO.COR.PERIGO}
                                icone={ICONE.EXCLUIR}
                                nome={"Excluir"}
                                titulo={"Excluir"}
                                funcao={listarEscalasPorStatus}
                                objeto={objeto}
                                url={`/hpm/escala/excluir/${escala.valor}`}
                            />
                        </div>;
                    }
                    if (escala.idStatus === 15) {
                        btnAlteracaoStatus = "";
                    }
                    return({
                        'nome': escala.nome,
                        'data_inicio': escala.dtInicio,
                        'data_termino': escala.dtTermino,
                        'situacao': escala.status,
                        'acoes':<div className={"row"}>
                                    {btnAlteracaoStatus}
                                </div>
                    })
                })
            )
        }
    }

    return(
        <Pagina titulo="Listar Escalas">
            <div className={"row"}>
                <div className={"col-lg-12"}>
                    <Card titulo="Listar">
                        <div className={"row"}>
                            <div className="col-lg-12">
                                <label>Status Escala</label>
                                <select
                                    className="form-control"
                                    name="idStatus"
                                    onChange={handleStatus}>
                                    <option hidden>Selecione...</option>
                                    {status.listaStatus.map((v, k) => {
                                        return <option className="flex-fill" value={v.id} key={k}> {v.nome}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </Card>
                    <Card titulo="Lista de Escalas">
                        <Tabela colunas={colunas} dados={dados()} pageSize={5} />
                    </Card>
                </div>
            </div>
        </Pagina>
    );
}