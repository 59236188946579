import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import logoHPM from '../../img/brasoes/brasao_hpm.png';

const PaginaSemLogin = ({ img, titulo, children }) => {
  useEffect(() => {
    document.getElementById('root').classList.add('login-page');
    return () => {
      document.getElementById('root').classList.remove('login-page');
    };
  }, []);

  const logo = img || logoHPM;

  return (
    <div className="login-box animated--fade-in">
      <div className="login-logo">
        <Link to="/">
          <img src={logo} alt="Brasão" />
        </Link>
      </div>
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <b>PORTAL</b>
          <span> HPM</span>
        </div>
        <div className="card-body login-card-body">
          <p className="login-box-msg">{titulo}</p>
          <div className="mb-3">{children}</div>
          <div className="social-auth-links text-center mt-2 mb-3"></div>
        </div>
      </div>
    </div>
  );
};

export default PaginaSemLogin;