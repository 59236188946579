import { useState, useRef, useEffect } from "react";
import { FormGroup, Form, Modal } from "react-bootstrap";
import { AutocompletarCid, Botao, Card, Input } from "..";
import { BOTAO, HttpVerbo, MSG } from "../../util/Constantes";
import { Editor } from "@tinymce/tinymce-react";
import { ExibirMensagem, xfetch } from "../../util";
import { BotaoExcluir } from "../Botao";
import { DataHoraAtual, formatarData } from "../../util/DataHora";

export default function ModalAdicionarInformacao(props) {  
  console.log(props.consulta)
  const dataHoraAtual = DataHoraAtual().split(',');
  const [hora, minuto] = dataHoraAtual[1].split(':');
  const dataHoraAtualFormatada = dataHoraAtual[0] + " - " + hora.trim() + ":" + minuto;

  const editorRef = useRef(null);
  const editorExFisico = useRef(null);
  const editorConduta = useRef(null);

  const [show, setShow] = useState(false);
  const [cids, setCids] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  let consultaSelecionada = {
    id: "",
    inicioAtendimento: formatarData(dataHoraAtualFormatada),
    fimAtendimento: formatarData(dataHoraAtualFormatada),
    anamnese: "",
    exameFisico: "",
    conduta: "",
    ordem: props.consulta.numOrdem,
    emergencia: props.consulta.emergencia,
    consultorioBlocoId: props.consulta.idConsultorioBloco,
    pessoaId: props.consulta.idPessoa,
    statusId: "",
    idCid: [],
  };

  const [consulta, setConsulta] = useState({
    idPessoa: props.consulta?.idPessoa || "",
    nmPaciente: props.consulta?.nmPaciente || "",
    dtNascimento: props.consulta?.dtNascimento || "",
    altura: props.consulta?.altura || "",
    peso: props.consulta?.peso || "",
    txtRelato: "",
    idade: null,
    imc: null,
    pessoas: [],
    idCid: [],
  });

  useEffect(() => {
    localStorage.setItem("arrayCids", JSON.stringify(consulta.idCid));
    localStorage.setItem("arrayCodigosCids", JSON.stringify(cids));
  }, [consulta.idCid, cids]);

  const AdicionarInformacao = async (statusId) => {
    consultaSelecionada.statusId = statusId;
    consultaSelecionada.idCid = consulta.idCid;
    if (editorRef.current || editorExFisico.current || editorConduta.current) {
      consultaSelecionada.anamnese = editorRef.current.getContent({
        format: "text",
      });
      if (consultaSelecionada.anamnese.length < 15) {
        ExibirMensagem("A Anamnese | Conduta | Exame Físico deve ter pelo menos 15 caracteres", MSG.ERRO);
        return;
      }
      consultaSelecionada.exameFisico = "";
      consultaSelecionada.conduta = "";
    }
    await xfetch(
      "/hpm/consulta/alterar",
      consultaSelecionada,
      HttpVerbo.POST
    ).then((json) => {
      console.log(consultaSelecionada);
      if (typeof json !== "undefined" ? json.status === "OK" : false) {
        ExibirMensagem("Consulta Salva Com Sucesso!", MSG.SUCESSO);
        handleClose()
      }
    });
  };

  const handleCID = () => {
    const idCid = document.getElementById("idcid").value;
    const idCidNome = document.getElementById("idcidAuto").value;
    let codigoCid = idCidNome.split(" - ");
    setConsulta({ ...consulta, idCid: [...consulta.idCid, Number(idCid)] });
    setCids([...cids, codigoCid[0]]);
    document.getElementById("idcidAuto").value = "";
  };

  const handleRemoveCid = (position) => {
    setConsulta({
      ...consulta,
      idCid: [...consulta.idCid.filter((_, index) => index !== position)],
    });
    setCids([...cids.filter((_, index) => index !== position)]);
  };

  return (
    <div>
      <Botao cor={props.corDoBotao} onClick={handleShow}>
        {props.nome}
      </Botao>
      <Modal show={show} onHide={handleClose} size="xl" scrollable={true}>
        <Modal.Header>
          <Modal.Title>{props.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12">
                <Card titulo={"Consultas"} scrollable={true} >
                  <div className="row">
                    <div className="col-lg-4">
                      <Input
                        type="text"
                        value={props.consulta.nmPaciente}
                        name="paciente"
                        label="Paciente"
                        placeholder="Paciente"
                        disabled
                        required
                      />
                    </div>
                    <div className="col-lg-2">
                      <Input
                        type="text"
                        value={props.consulta.cpfPaciente}
                        name="cpf"
                        label="CPF"
                        placeholder={"CPF"}
                        disabled
                      />
                    </div>
                    <div className="col-lg-2">
                      <Input
                        type="text"
                        value={props.consulta.nmInstituicao}
                        name="Instituição"
                        label="Instituição"
                        placeholder="Instituição"
                        disabled
                      />
                    </div>
                    <div className="col-lg-2">
                      <Input
                        type="text"
                        value={props.consulta.nmEspecialidade}
                        name="Especialidade"
                        label="Especialidade"
                        placeholder="Especialidade"
                        disabled
                      />
                    </div>
                    <div className="col-lg-2">
                      <Input
                        type="text"
                        value={props.consulta.dtHora}
                        name="Data - Hora"
                        label="Data - Hora"
                        placeholder="Data - Hora"
                        disabled
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-10">
                      <Input
                        type="text"
                        value={props.consulta.anamnese}
                        name="Anamnese | Conduta | Exame Físico"
                        label="Anamnese | Conduta | Exame Físico"
                        placeholder="Anamnese | Conduta | Exame Físico"
                        disabled
                      />
                    </div>
                    <div className="col-lg-2">
                      <Input
                        type="text"
                        value={props.consulta.cidList[0] === '' ? ' ' : props.consulta.cidList[0]}
                        name="CID"
                        label="CID"
                        placeholder="CID"
                        disabled
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            { props.consulta.idMedico === Number(localStorage.getItem("id")) ?             <Card titulo={"Adicionar Nova Informação"}>
              <div className="row">
                <div className="col-lg-12">
                  <AutocompletarCid
                    name="cid"
                    url={"/hpm/cid/"}
                    label="Digite o CID:"
                    placeholder="Nome ou código aqui"
                    tamanho={3}
                    retorno={handleCID}
                  />
                  <br />
                  <FormGroup>
                    <div className={"col-lg-12"}>
                      {cids.map((cid, index) => (
                        <FormGroup
                          key={index}
                          className={"col-lg-3"}
                          style={{ display: "inline-flex" }}
                        >
                          <div
                            className={"info-box col-lg-12"}
                            style={{ display: "flex" }}
                          >
                            <div key={index} className="info-box-content">
                              <span className="info-box-text z-3 ">CID</span>
                              <span className="info-box-text z-3 ">{cid}</span>
                            </div>
                            <BotaoExcluir
                              style={{ marginLeft: "1em" }}
                              onClick={() => {
                                handleRemoveCid(index);
                              }}
                            />
                          </div>
                        </FormGroup>
                      ))}
                    </div>
                  </FormGroup>
                </div>
                <br />
                <div className="col-lg-12">
                  <label>Anamnese | Conduta | Exame Físico</label>
                  <Editor
                    apiKey="sch2exkkk528sadnq24kffc3nidi73p0g4uma1gw2ubb112y"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    placeholder={"Insira aqui o texto."}
                    init={{
                      height: 200,
                      menubar: props.menuBara,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>
            </Card> : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {
            props.consulta.idMedico === Number(localStorage.getItem("id")) ?   <Botao
            cor={props.corDoBotao}
            icone={props.icone}
            onClick={() => AdicionarInformacao(Number("7"))}
          >
            Adicionar
          </Botao> : null
          }
          <Botao cor={BOTAO.COR.SECUNDARIO} onClick={handleClose}>
            Fechar
          </Botao>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
