import {
  Autocompletar,
  Botao,
  BotaoPesquisar,
  Card,
  Input,
  Pagina,
  Tabela,
} from "../../componentes";
import { BOTAO, HttpVerbo, ICONE, MSG } from "../../util/Constantes";
import React, { useEffect, useState } from "react";
import { ExibirMensagem, xfetch } from "../../util";
import { Modal } from "react-bootstrap";

export default function ListarAtestados() {
  const [showModal, setShowModal] = useState(false);
  const SIZE = 5;
  const STATUS_ATESTADO_OBJ = 37;
  const [pesquisado, setPesquisado] = useState(false);
  const [listaAtestados, setListaAtestados] = useState({
    atestados: [
      {
        id: "",
        motivo: {
          nome: "",
        },
        dtEmissaoAtestado: "",
        diasAfastamento: "",
        paciente: "",
      },
    ],
  });
  const [status, setStatus] = useState({
    listarStatus: [],
  });
  const [pesquisarAtestados, setPesquisarAtestados] = useState({
    pacienteId: null,
    dataInicio: null,
    dataFim: null,
    statusAtestadoId: null,
  });

  const handleCloseModal = () => {
    setShowModal(false);
};

  const selecionarPessoa = (e) => {
    let idpessoa = document.getElementById("idpessoa").value;
    setPesquisarAtestados({ ...pesquisarAtestados, pacienteId: idpessoa });
  };

  const handleDtInicial = (e) => {
    const dtInicial = new Date(e.target.value);
    const [ano, mes, dia] = e.target.value.split("-");
    console.log(dia + "/" + mes + "/" + ano);
    setPesquisarAtestados({
      ...pesquisarAtestados,
      dataInicio: e.target.value,
    });
  };

  const handleDtFim = (e) => {
    const dtFim = new Date(e.target.value);
    const [ano, mes, dia] = e.target.value.split("-");
    console.log(dia + "/" + mes + "/" + ano);
    setPesquisarAtestados({ ...pesquisarAtestados, dataFim: e.target.value });
  };

  const handleStatus = (e) => {
    setPesquisarAtestados({
      ...pesquisarAtestados,
      statusAtestadoId: e.target.value,
    });
    console.log("Status selecionado:", e.target.value);
  };

  const handleListarAtestados = () => {
    if (
      pesquisarAtestados.dataInicio === null ||
      pesquisarAtestados.dataFim === null
    ) {
      return;
    }
    console.log("Pesquisar Atestados:", pesquisarAtestados);
    xfetch(
      `/hpm/atestado/atestados/recepcao`,
      pesquisarAtestados,
      HttpVerbo.POST
    )
      // .then(response => response.json())
      .then((atestado) => {
        // atestado.content vem do endpoint que é uma paginação
        setListaAtestados({ ...listaAtestados, atestados: atestado.content });
      });
    return listaAtestados;
  };

  const listarStatus = () => {
    xfetch(`/hpm/status/${STATUS_ATESTADO_OBJ}`, {}, HttpVerbo.GET)
      .then((res) => res.json())
      .then((status) =>
        setStatus({ ...status, listaStatus: status.resultado })
      );
  };

  const enviar = () => {
    handleListarAtestados();
    setPesquisado(!pesquisado);
  };

  useEffect(() => {
    listarStatus();
  }, []);

  useEffect(() => {
    handleListarAtestados();
  }, [pesquisado]);

  const colunas = [
    { text: "Nome do Paciente" },
    { text: "Motivo" },
    { text: "Data Emissão" },
    { text: "Dias de Afastamento" },
    { text: "Ação" },
  ];

  const dados = () => {
    return typeof listaAtestados.atestados !== "undefined"
      ? listaAtestados.atestados.map((atestado) => {
          const dataEmissao = new Date(
            atestado.dtEmissaoAtestado
          ).toLocaleDateString("pt-BR");
          console.log("Data de Emissão:", atestado.motivo.nome);
          console.log(atestado);
          return {
            nome_do_paciente: atestado.paciente.nome,
            motivo: atestado.motivo.nome,
            data_emissao: dataEmissao !== "Invalid Date" ? dataEmissao : null,
            dias_de_afastamento: atestado.diasAfastamento,
            acao: (
              <div>
                <Botao onClick={() => setShowModal(true)}>Visualizar Atestado</Botao>
              </div>
            ),
          };
        })
      : null;
  };

  return (
    <Pagina titulo={"Listar Atestados"}>
      <div className="row">
        <div className="col-lg-12">
          <Card titulo="Ver Atestados">
            <div className="row">
              <input type="hidden" name="idPessoa" />
              <div className={"col-lg-3"}>
                <Autocompletar
                  name="pessoa"
                  url="/hpm/pessoa/"
                  label="Digite os Dados:"
                  placeholder="Nome ou CPF aqui"
                  tamanho={6}
                  retorno={selecionarPessoa}
                />
              </div>
              <div className={"col-lg-3"}>
                <Input
                  type="datetime-local"
                  value={pesquisarAtestados.dataInicial}
                  onChange={(e) => handleDtInicial(e)}
                  name="dataInicial"
                  label="Data Inicial"
                  placeholder="Data"
                />
              </div>
              <div className={"col-lg-3"}>
                <Input
                  type="datetime-local"
                  value={pesquisarAtestados.dataFim}
                  onChange={(e) => handleDtFim(e)}
                  name="dataFim"
                  label="Data de Retorno"
                  placeholder="Data"
                />
              </div>
              <div className="col-lg-3">
                <label>Status</label>
                <select
                  className="form-control"
                  name="idStatus"
                  value={status.idStatus}
                  onChange={handleStatus}
                >
                  <option hidden>Selecione...</option>
                  {typeof status.listaStatus !== "undefined"
                    ? status.listaStatus.map((v, k) => {
                        if (v.id !== 15) {
                          return (
                            <option className="flex-fill" value={v.id} key={k}>
                              {" "}
                              {v.nome}
                            </option>
                          );
                        }
                      })
                    : ""}
                </select>
              </div>
              <div className="col-lg-12 text-lg-right mt-4 mb-4">
                <BotaoPesquisar onClick={enviar} />
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Card titulo={"Lista de atestados"}>
        <Tabela colunas={colunas} dados={dados()} pageSize={SIZE} />
        <Modal show={showModal}>
          <Modal.Header>
            <Modal.Title>Atestados</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Botao variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Botao>
          </Modal.Footer>
        </Modal>
      </Card>
    </Pagina>
  );
}
