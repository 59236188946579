import React, {useEffect, useState} from "react";
import {BotaoSalvar, Card, Input, Pagina, Tabela} from "../../componentes";
import {ExibirMensagem, xfetch} from "../../util";
import {BOTAO, HttpVerbo, ICONE, MESES, MSG} from "../../util/Constantes";
import ModalFormExcluir from "../../componentes/modal/ModalFormExcluir";
import ModalFormAlterar from "../../componentes/modal/ModalFormAlterar";
import {list} from "bootstrap/grunt/browsers";

export default function CadastrarEscala() {
    const [lista, setLista] = useState({
        escalas: [
            {
                valor: "",
                nome: "",
                status: "",
                dtInicio: "",
                dtTermino: "",
                nmTipoEscala: ""
            }
        ]
    });

    const [objeto, setObjeto] = useState({
        nome: "",
        dataInicio: "",
        dataTermino: "",
        idStatus: "",
        tipoEscala: "",
    });

    const [statusSelecionado, setStatusSelecionado] = useState({
        idStatusSelecionado: null
    });

    const [status, setStatus] = useState({
        listaStatus: []
    });

    const [tipo, setTipo] = useState({
        listaTipo: []
    });

    const [verificador, setVerificador] = useState({
        ano: 0,
        mesInicio: 0,
        mesTermino: 0,
        mesEscala: 0,
        anoEscala: 0
    });

    const escalaObjeto = 35;
    const statusObjeto = 31;
    let dt = "";

    const handleDtHrInicio = (e) => {
        dt = e.target.value;
        let mesVerificador = dt.split("-");
        verificador.mesInicio = Number(mesVerificador[1]);
        verificador.ano = Number(mesVerificador[0]);
        setObjeto({...objeto, dataInicio: dt});
    }

    const handleDtHrTermino = (e) => {
        dt = e.target.value;
        let mesVerificador = dt.split("-");
        verificador.mesTermino = Number(mesVerificador[1]);
        verificador.ano = Number(mesVerificador[0]);
        setObjeto({...objeto, dataTermino: dt});
    }

    const handleChange = (e) => {
        const myDate = e.target.value;
        let [ano, mes] = myDate.split("-");
        verificador.mesEscala = Number(mes);
        mes = MESES[mes - 1];
        objeto.nome = mes + " - " + ano;
        verificador.anoEscala = Number(ano);
    }

    const handleStatus = (e) => {
        statusSelecionado.idStatusSelecionado = e.target.value;
        setStatusSelecionado({...statusSelecionado, idStatusSelecionado: e.target.value});
        listarEscalasPorStatus();
    }

    const handleTipo = (e) => {
        setObjeto({...objeto, tipoEscala: e.target.value});
    }

    const listarEscalasPorStatus = () => {
        if (statusSelecionado.idStatusSelecionado !== null) {
            xfetch(`/hpm/escala/junta/${statusSelecionado.idStatusSelecionado}/opcoes`, {}, HttpVerbo.GET)
                .then(res => res.json())
                .then(lista => setLista({...lista, escalas: lista.resultado}))
            console.log('Lista:', lista)
        }
    }

    let msg = "";

    const enviar = (e) => {
        if (verificador.mesInicio === verificador.mesEscala &&
            verificador.mesTermino === verificador.mesEscala &&
            verificador.ano === verificador.anoEscala) {
            objeto.idStatus = statusSelecionado.idStatusSelecionado;
            xfetch('/hpm/escala/junta/cadastrar', objeto, HttpVerbo.POST)
                .then( json =>{
                        if (typeof json !== "undefined" ? json.status === "OK" : null) {
                            ExibirMensagem('Escala Cadastrada Com Sucesso!', MSG.SUCESSO, '', '', '', '', listarEscalasPorStatus);
                        }
                    }
                )
            console.log('Objeto no Envio:', objeto)
        } else {
            ExibirMensagem("Mês selecionado para nome da escala não pode ser diferente do mês de início e término da escala!", MSG.ALERTA);
        }
    }

    useEffect(() => {
        listarEscalasPorStatus();
        xfetch(`/hpm/tipo/${escalaObjeto}`, {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(tipo => setTipo({...tipo, listaTipo: tipo.resultado}))
        xfetch(`/hpm/status/${statusObjeto}`, {}, HttpVerbo.GET)
            .then(res => res.json())
            .then(status => setStatus({...status, listaStatus: status.resultado}))
    }, [])

    const botaoExcluir = (id) => <ModalFormExcluir
        corDoBotao={BOTAO.COR.PERIGO}
        icone={ICONE.EXCLUIR}
        titulo={"Excluir"}
        nome={"Excluir"}
        objeto={objeto}
        url={`/hpm/escala/junta/excluir/${id}`}
        funcao={listarEscalasPorStatus}
    />;

    const colunas = [
        {text: "Nome Escala"},
        {text: "Tipo Escala"},
        {text: "Status"},
        {text: "Data Início"},
        {text: "Data Término"},
        {text: "Ações"}
    ]

    const dados = () => {
        return (
            typeof lista.escalas !== "undefined" ? lista.escalas.map((escala) => {
                const urlEscala = `/hpm/escala/junta/alterar/${escala.valor}`;
                let statusId = "";
                let btnAlteracaoStatus = "";
                if (escala.idStatus === 13) {
                    statusId = 14;
                    msg = "Inativada";
                    btnAlteracaoStatus = <div id={"btnAlteracao"}>
                        <ModalFormAlterar
                            escala={escala}
                            statusId={statusId}
                            corDoBotao={BOTAO.COR.PRIMARIO}
                            icone={ICONE.INFO}
                            titulo={"Inativar"}
                            nome={"Inativar"}
                            texto={"Escala"}
                            msg={msg}
                            verbo={HttpVerbo.PUT}
                            url={urlEscala}
                            funcao={listarEscalasPorStatus}
                        />
                        { botaoExcluir(escala.valor) }
                    </div>;
                }
                if (escala.idStatus === 14) {
                    statusId = 15;
                    let statusAtivar = 13;
                    msg = "Finalizada!";
                    btnAlteracaoStatus = <div id={"btnAlteracao"}>
                        <ModalFormAlterar
                            escala={escala}
                            statusId={statusAtivar}
                            corDoBotao={BOTAO.COR.SUCESSO}
                            icone={ICONE.INFO}
                            titulo={"Ativar"}
                            nome={"Ativar"}
                            texto={"Escala"}
                            msg={"Ativada"}
                            verbo={HttpVerbo.PUT}
                            url={urlEscala}
                            funcao={listarEscalasPorStatus}
                        />
                        <ModalFormAlterar
                            escala={escala}
                            statusId={statusId}
                            corDoBotao={BOTAO.COR.ALERTA}
                            icone={ICONE.ENVIAR}
                            titulo={"Finalizar"}
                            nome={"Finalizar"}
                            texto={"Escala"}
                            msg={msg}
                            verbo={HttpVerbo.PUT}
                            url={urlEscala}
                            funcao={listarEscalasPorStatus}
                        />
                        { botaoExcluir(escala.valor) }
                    </div>;
                }
                if (escala.idStatus === 15) {
                    btnAlteracaoStatus = "";
                }
                return ({
                    nome_escala: escala.nome,
                    tipo_escala: escala.nmTipoEscala,
                    status: escala.status,
                    data_inicio: escala.dtInicio,
                    data_termino: escala.dtTermino,
                    acoes: <div className={"row"}>
                        {btnAlteracaoStatus}
                    </div>
                })
            }) : null
        )
    }

    return (
        <Pagina titulo="Cadastrar Escala - JISM">
            <div className="row">
                <div className="col-lg-12">
                    <Card titulo="Cadastrar">
                        <div className={"row"}>
                            <div className={"col-lg-2"}>
                                <Input
                                    id={"date-input"}
                                    type="Month"
                                    onChange={handleChange}
                                    name="nome"
                                    label="Nome da Escala"/>
                            </div>
                            <div className="col-lg-2">
                                <label>Status</label>
                                <select
                                    className="form-control"
                                    name="idStatus"
                                    value={statusSelecionado.idStatusSelecionado}
                                    onChange={handleStatus}>
                                    <option hidden>Selecione...</option>
                                    {status.listaStatus !== "undefined" ? status.listaStatus.map((v) => {
                                        return <option className="flex-fill" value={v.id !== null ? v.id : ""} key={v.id}> {v.nome}</option>
                                    }) : null}
                                </select>
                            </div>
                            <div className="col-lg-2">
                                <label>Tipo Escala</label>
                                <select
                                    className="form-control"
                                    name="idTipo"
                                    onChange={handleTipo}>
                                    <option hidden>Selecione...</option>
                                    {tipo.listaTipo !== "undefined" ? tipo.listaTipo.map((v) =>
                                        <option className="flex-fill" value={v.id !== null ? v.id : ""} key={v.id}> {v.nome}</option>
                                    ) : null}
                                </select>
                            </div>
                            <div className="col-lg-3">
                                <Input
                                    type="datetime-local"
                                    value={objeto.dataInicio !== null ? objeto.dataInicio : ''}
                                    onChange={handleDtHrInicio}
                                    name="dataInicio"
                                    label="Data e hora início"
                                    placeholder="Data e hora"/>
                            </div>
                            <div className="col-lg-3">
                                <Input
                                    type="datetime-local"
                                    value={objeto.dataTermino !== null ? objeto.dataTermino : ''}
                                    onChange={handleDtHrTermino}
                                    name="dataTermino"
                                    label="Data e hora término"
                                    placeholder="Data e hora"/>
                            </div>
                        </div>
                        <div className="col-lg-15 text-lg-right mt-4 mb-4">
                            <BotaoSalvar onClick={enviar}/>
                        </div>
                    </Card>
                    <Card titulo="Escalas Cadastradas">
                        <Tabela colunas={colunas} dados={dados()} pageSize={5}/>
                    </Card>
                </div>
            </div>
        </Pagina>
    );
}